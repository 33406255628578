<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="container m-0 p-0 t-message">
    <p>
      <span class="message-author">{{ message.author }}</span>
      <span class="message-date"> {{ formatDateTime(message.createdAt) }}</span>
    </p>
    <p class="message-comment">
      <QuillEditor
      v-model:content="message.comment"
      contentType="html"
      class="quillNew"
      theme=""
      :readOnly="true"
    />
      <!-- {{ message.comment }} -->
    </p>
  </div>
</template>

<script setup>

import moment from "moment/moment";
import { defineProps } from "vue";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    message: {
      type: Object,
      required: true
    }
  })


  const formatDateTime = (value) => {
    if (value) {
      return moment(value).format("DD/MM/YYYY");
    }
    return "";
  }

</script>
<style lang="scss" scoped>
  p{
    padding-bottom: 1px!important;
    margin-bottom: 0!important;
  }
  .message-date {
    color: red;
    font-family: "Montserrat";
    font-size: 20px;
  }
  .message-author {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 20px;
    margin-right: 5px;
  }
  .message-comment {
    font-family: "Montserrat";
    font-size: 20px;
    padding-top: 0!important;
  }
  .t-message {
    border-bottom: 1px solid;
    margin-bottom: 15px!important;
  }
</style>