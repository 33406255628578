<template>
  <div>
    <header-title title="MisSugerencias" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/speaker-icon.svg" width="50" alt="">
      <div class="title-text ml-4">
        SUGERENCIAS
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="d-flex flex-column flex-nowrap mt-4 w-100 label-common">
          <div class="form-item">
            <label for="title">T&iacute;tulo</label>
            <el-input
                id="title"
                v-model="title"
                placeholder="Ingrese título"
            />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-flex flex-column flex-nowrap mt-4 w-100 label-common">
          <div class="form-item date-picker">
            <label for="fregister" class="w-100">Fecha de registro</label>
            <el-date-picker
                id="fregister"
                v-model="fregister"
                :name="'from-date'"
                type="date"
                placeholder="Fecha de registro"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                :prefix-icon="customPrefix"
                :picker-options="{ firstDayOfWeek: 1 }"
                :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 d-flex flex-nowrap flex-column mt-4 label-common">
        <div class="form-item date-picker">
          <label for="id-employee" class="">Autor</label>
          <el-input
              id="input-employee"
              v-model="employee_name"
              class="search-input"
              :color="'light'"

              class_div_col_label="col-md-1"
              class_div_col_input="col-md-11"
              :disabled="true"
          />
        </div>
      </div>
      <div  class="col-md-4 d-flex flex-nowrap flex-column mt-4 label-common">
        <div class="form-item">
          <label for="responsible" class="w-100">Responsable</label>
          <el-select
              id="id-responsible"
              v-model="projectManager"
              serchable
              clearable
              placeholder="TODOS"
              :disabled="!isAdmin"
          >
            <el-option
                v-for="item in optionsResponsible"
                :key="item.id"
                :label="item.fullName"
                :value="item['@id']"
            />
          </el-select>
        </div>
      </div>
      <div class="col-md-4 d-flex flex-nowrap flex-column mt-4 label-common">
        <div class="form-item">
          <label for="id-status" class="w-100">Estado</label>
          <div>
            <el-select
                id="id-status"
                v-model="suggestionStatus"
                serchable
                clearable
                :readonly="!isEdit"
                placeholder="TODOS"
                :disabled="!isAdmin"
            >
              <el-option
                  v-for="item in optionsStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item['@id']"
              />
            </el-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
          class="col-md-4 d-flex flex-nowrap flex-column mt-4 label-common"
      >
        <div class="form-item">
          <label for="id-improvement" class="w-100">&Aacute;rea de mejora</label>
          <el-select
              id="id-improvement"
              v-model="department"
              serchable
              clearable
              placeholder="TODOS"
          >
            <el-option
                v-for="item in optionsImprovements"
                :key="item.id"
                :label="item.name"
                :value="item['@id']"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="row ml-1 mt-5 mb-4 label-common">
      <div class="form-item">
        <label class="w-100">Descripci&oacute;n</label>
        <textarea
            v-model="description"
            class="w-100 p-3 font18"
            rows="10"
        />
      </div>
    </div>
    <div v-if="update" class="row ml-1 mt-5 label-common">
      <div class="form-item">
        <label>Acci&oacute;n realizada</label>
        <textarea
            v-model="actionperformed"
            class="w-100 p-3 font18"
            rows="10"
            :disabled="!isAdmin"
        />
      </div>
    </div>

    <div
        v-if="update"
        class="row ml-1"
    >
      <div class="d-flex flex-column flex-nowrap mt-4 label-common">
        <div class="form-item date-picker">
          <label for="faction">Fecha de acci&oacute;n</label>
          <el-date-picker
              id="faction"
              v-model="faction"
              :name="'from-date'"
              type="date"
              placeholder="Fecha de acción"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              :prefix-icon="customPrefix"
              :picker-options="{ firstDayOfWeek: 1 }"
              :disabled="!isAdmin"
          />
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
          class="btn btn_yellow btn-search "
          @click="goBack()"
      >
        VOLVER
      </button>
      <button
          class="btn btn_red btn-search ml-4"
          @click="createSugg()"
      >
        GUARDAR
      </button>
    </div>
  </div>
</template>
<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import EmployeeServices from "@/api/services/employee.service";
import SuggestionServices from "@/api/services/suggestion.service";
import DepartmentServices from "@/api/services/department.service";
/*import ProjectManagerServices from "@/api/services/projectManager.service";*/
import notification from '@/utils/plugins/NotificationService'
import {h, shallowRef, defineProps, ref, computed, watch, onMounted} from "vue";
import {useStore} from "vuex";
/* import moment from "moment"; */


const props = defineProps({
  suggestion:Object,
  update:Boolean
})
const store = useStore();

// eslint-disable-next-line no-unused-vars
const user = ref(null);
const fregister = ref(new Date());
const title = ref(null);
// eslint-disable-next-line no-unused-vars
const optionsEmployee = ref([]);
const employee = ref(null);
const projectManager = ref(null);
const optionsResponsible = ref([]);
const suggestionStatus = ref(null);
const optionsStatus = ref([]);
const department = ref(null);
const optionsImprovements = ref([]);
const actionperformed = ref(null);
const description = ref(null);
const faction = ref(new Date());
const isEdit = ref(false);

const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
const isAdmin = computed(() => {
  const user = store.getters.getUserLogged;
  return user.roles.includes('ROLE_ADMIN');
});
const employee_name = computed({
  get(){
    if(!employee.value) return '';
    return employee.value.name+' '+employee.value.lastName;
  },
  set(setName){
    return setName;
  }
});

watch(props.update, (val) => {
  if(val){
    Promise.all([getEmployees(), getStatus(), getDepartments(),getProjectManager()]).then(() => {
      loadData(props.suggestion)
    });
  }
});

onMounted(() => {
  Promise.all([getEmployees(), getStatus(), getDepartments(), getProjectManager()]).then(() => {
    if (props.update){
      loadData(props.suggestion)
    }else{
      let year = fregister.value.getFullYear();
      let month = fregister.value.getMonth() + 1; // getMonth() returns month from 0 to 11
      let day = fregister.value.getDate();
      month = month.toString().padStart(2, '0');
      day = day.toString().padStart(2, '0');

      fregister.value = `${year}-${month}-${day}`;

    }

  });
})
  const loadData = (sgg) => {
    //console.log("Sugg",sgg);
    title.value = sgg.title;
    description.value = sgg.description;
    fregister.value = sgg.timestamp;
    employee.value = sgg.author;
    if(sgg.responsible){
      sgg.responsible.firstName = sgg.responsible ? sgg.responsible.name : '';
      sgg.responsible.name = sgg.responsible ? sgg.responsible.firstName +' '+sgg.responsible.lastName : null
      projectManager.value = sgg.responsible;
    }
    projectManager.value = typeof sgg.responsible === 'object' ? sgg.responsible['@id'] : sgg.responsible
    actionperformed.value = sgg.action;

    if(sgg.actionDate){
        faction.value = sgg.actionDate;
    }
    if(sgg.suggestionStatus){
      isEdit.value = true;
      suggestionStatus.value = sgg.suggestionStatus['@id'];
    }
    if(sgg.department)
      department.value = sgg.department['@id'];

  }

  const createSugg = () =>{
    if(!title.value)
    {
      notification.addNotification("El campo título no debe ser vacío.", 'error', 6000);
      return;
    } else if (!description.value) {
      notification.addNotification("El campo descripción no debe ser vacío.", 'error', 6000);
      return;

    }else if (!suggestionStatus.value) {
      notification.addNotification("El campo estado no debe ser vacío.", 'error', 6000);
      return;
    }
    const item = {
      title:title.value,
      description:description.value,
      timestamp:fregister.value,
      suggestionStatus: suggestionStatus.value,
      department: department.value,
      responsible: projectManager.value && projectManager.value['@id'] ? projectManager.value['@id'] : projectManager.value,
      author: employee.value['@id'],
      action:"",
      /*actionDate:faction */
    }
    if(props.update){
      item.actionDate=faction.value;
      item.action = actionperformed.value;
      SuggestionServices.updateSuggestions(props.suggestion.id,item).then(()=>{
        goBack();
      });
    }else{
      SuggestionServices.createSuggestions(item).then(()=>{
        goBack();
      });
    }

  }
  const goBack = () =>{
    history.back();
  }
  const getDepartments = async() =>{
    employee.value = store.getters.getUserLogged;
    return await   DepartmentServices.getDepartments({
      itemsPerPage:9999,
      page:1,
      'order[name]':'ASC'
    }).then((resp)=>{

      optionsImprovements.value = resp.data['hydra:member'];

      return true;
    });


  }
  const getEmployees = async() =>{

    employee.value = store.getters.getUserLogged;
    /* console.log("Employee loggued",employee);*/
    /*  return await   EmpleyeeServices.getEmployees({itemsPerPage:9999,page:1,email:user.email}).then((resp)=>{
              //console.log("Select emp",resp.data['hydra:member'])
            optionsEmployee = resp.data['hydra:member'];
            employee = optionsEmployee[0];
            return true;


        }).catch(()=>{

        })
        ; */


  }
  const getStatus= async() =>{
    return await SuggestionServices.getStatus({itemsPerPage:9999,page:1})
        .then((resp)=>{
          optionsStatus.value = resp.data['hydra:member'];
          suggestionStatus.value = optionsStatus.value[0]['@id'];
          isEdit.value = false;
          return true;
        })


  }
  const getProjectManager = async() =>{

    if(!isAdmin.value){
    /*  console.log("Select emp",responsible.value);
      if(!responsible.value) return;
      return await  EmployeeServices.getEmployee(responsible.value).then((resp)=>{

        optionsResponsible.value = resp.data;
        optionsResponsible.value.firstName = optionsResponsible.value.name;
        optionsResponsible.value.name = optionsResponsible.value.firstName + ' ' + optionsResponsible.value.lastName
        return true;


      })*/
    }else{
      return await  EmployeeServices.getEmployees({
        itemsPerPage:9999,
        page:1,
        active:true,
        'order[name]':'ASC'
      }).then((resp)=>{
        //console.log("Select emp",resp.data['hydra:member'])
        optionsResponsible.value = resp.data['hydra:member'];
        // optionsResponsible.value = resp.data['hydra:member'].map(v=>{
        //   v.firstName = v.name;
        //   v.name = v.firstName+' '+v.lastName;
        //   return v;
        // });
        return true;


      })
    }
  }
</script>
<style lang="scss" scoped>
.btn-search {
  &.btn_yellow {
    color: $bg-blue;
  }
  &.btn_red {
    color: white;
  }
  width: 20em;
  height: 3em;

  font-weight: bolder;
  text-transform: uppercase;
  width: 200px;
  height: 40px;
}
.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}
.card-search {
  border-radius: 15px;
  height: 150px;
  background: url("../../assets/icons/altavoz.png"), white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}
#icon-search {
  fill: $bg-blue;
  width: 6%;
}
#icon-search-calendar {
  fill: $bg-blue;
  width: 20px;
  height: 20px;
}
.title-text {
  text-align: center;
  align-self: center;
  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}
.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
      #icon-search {
        fill: $bg-blue;
        width: 2.9em;
      }
      #icon-user {
        fill: $bg-blue;
        width: 1.6em;
      }
    }
    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
