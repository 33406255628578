<template>
  <div class="container-fluid">
    <div class="w-100">
      <el-row :gutter="20">
        <el-col
            :span="6"
            class="d-flex align-items-center"
        >
          <img
              src="@/assets/icons/group.svg"
              alt=""
              class="mt-3"
          >
          <div class="title-text ml-4 mt-3">
            Tickets
          </div>
        </el-col>
        <el-col
            v-if="formModel"
            :span="18"
        >
          <div>
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="form-item">
                  <label for="project mr-2">Proyecto</label>
                  <el-select
                      placeholder="Selecciona primero un Proyecto"
                      :disabled="action !== 'create' && ((ticket.requestingEmployee && store.state.user.id !== ticket.requestingEmployee.id) || !ticket.requestingEmployee)"
                      filterable
                      v-model="formModel.project"
                      clearable
                  >
                    <el-option
                        v-for="item in projects"
                        :key="item.id"
                        :label="item.name + ' - ' + item.client.name"
                        :value="item['@id']"
                    >
                      <span>{{ item.name }}</span>
                      <span> - </span>
                      <span style="color: #8492A6">{{ item.client.name }}</span>
                    </el-option>
                  </el-select>
                </div>
                <p
                    v-if="errorList && errorList.project"
                    class="animated fadeIn invalid-feedback"
                >
                  {{ errorList.project }}
                </p>
              </el-col>
              <el-col
                  v-if="action !== 'create'"
                  :span="12"
              >
                <div class="form-item">
                  <label for="code">Código</label>
                  <el-input
                      disabled
                      :value="'['+formModel.tag+'] - '+formModel.id"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
        v-if="formModel && formModel.project"
        class="card mb-5 wback"
        style="background-image: '@/assets/images/ticket-back.png';"
    >
      <div class="card-body">
        <el-row :gutter="20">
          <el-col
              v-if="action !== 'create' && formModel && canChangeStatus"
              :span="8"
          >
            <div class="form-item">
              <label for="state">Estado</label>
              <el-select
                  @change="changeState"
                  v-model="formModel.status"
                  filterable
                  :disabled="!user.departmentManager && (!isAssignedUser || isRequestedUser)"
              >
                <el-option
                    v-for="item in states"
                    :key="item.id"
                    :label="item.name"
                    :value="item['@id']"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="form-item">
              <label for="issue">Asunto</label>
              <el-input
                  v-model="formModel.issue"/>
            </div>
            <p
                v-if="errorList && errorList.issue"
                class="animated fadeIn invalid-feedback"
            >
              {{ errorList.issue }}
            </p>
          </el-col>
          <el-col :span="8">
            <div class="form-item">
              <label
                  for="typeTicket"
                  class="w-100">Tipo</label>
              <el-select
                  class="w-100"
                  filterable
                  v-model="formModel.ticketType"
                  size="large">
                <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.name"
                    :value="item['@id']"
                />
              </el-select>
            </div>
            <p
                v-if="errorList && errorList.ticketType"
                class="animated fadeIn invalid-feedback"
            >
              {{ errorList.ticketType }}
            </p>
          </el-col>
          <el-col :span="8">
            <div class="form-item">
              <label
                  for="department"
                  class="w-100">Departamento al que solicita</label>
              <el-select
                  class="w-100"
                  filterable
                  :disabled="!hasRoleAdmin"
                  v-model="formModel.department">
                <el-option
                    v-for="item in optionsDepartment"
                    :key="item.id"
                    :label="item.name"
                    :value="item['@id']"
                />
              </el-select>
              <p
                  v-if="errorList && errorList.department"
                  class="animated fadeIn invalid-feedback"
              >
                {{ errorList.department }}
              </p>
            </div>
          </el-col>
          <el-col
              :span="8"
              v-if="hasRoleAdmin || action !== 'create'">
            <div class="form-item">
              <label
                  for="user"
                  class="w-100">Usuario asignado</label>
              <el-select
                  :disabled="!hasRoleAdmin"
                  class="w-100"
                  filterable
                  v-model="formModel.assignedEmployee">
                <el-option
                    v-for="item in employees"
                    :key="item.id"
                    :label="item.fullName"
                    :value="item['@id']"
                />
              </el-select>
            </div>
          </el-col>
          <el-col
              v-if="props.action==='update' && hasRoleAdmin"
              :span="8"
          >
            <div style="margin-top: 2.8em;">
              <button
                  class="btn btn_yellow btn-assign"
                  @click="assign()"
              >
                ASIGNAR
              </button>
            </div>
          </el-col>
          <el-col
              v-if="props.action==='update' && hasRoleAdmin && startButtonLabel"
              :span="8"
          >
            <div style="margin-top: 2.8em;">
              <button
                  class="btn btn_yellow btn-assign"
                  @click="startButton"
              >
                {{ startButtonLabel }}
              </button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>


    <el-row
        v-if="formModel && formModel.project"
        :gutter="10"
    >
      <el-col :lg="8">
        <div class="form-item">
          <label
              for="user"
              class="w-100">Solicitante</label>
          <el-select
              class="w-100"
              filterable
              v-model="formModel.requestingEmployee">
            <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.fullName"
                :value="item['@id']"
            />
          </el-select>
        </div>
        <p
            v-if="errorList && errorList.requestingEmployee"
            class="animated fadeIn invalid-feedback"
        >
          {{ errorList.requestingEmployee }}
        </p>
      </el-col>
      <el-col :lg="8">
        <div class="form-item">
          <label for="phone">Teléfono/Extensión</label>
          <el-input
              v-model="formModel.phone"
              type="text"
          />
          <p
              v-if="errorList && errorList.phone"
              class="animated fadeIn invalid-feedback"
          >
            {{ errorList.phone }}
          </p>
        </div>
      </el-col>
      <el-col :lg="8">
        <el-row :gutter="10">
          <el-col :lg="12">
            <div class="form-item date-picker">
              <label for="createdAt">Fecha creación</label>
              <el-date-picker
                  v-model="formModel.createdAt"
                  type="datetime"
                  format="YYYY/MM/DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm"
                  :picker-options="{ firstDayOfWeek: 1 }"
              />
            </div>
          </el-col>
          <el-col :lg="12">
            <div class="form-item date-picker">
              <label for="createdAt">Fecha cierre</label>
              <el-date-picker
                  v-model="formModel.closingDate"
                  disabled
                  name="from-date"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :picker-options="{ firstDayOfWeek: 1 }"
              />
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label
              for="affectedEmployee"
              class="w-100">Empleado afectado</label>
          <el-select
              class="w-100"
              filterable
              v-model="formModel.affectedEmployee">
            <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.name + ' ' + item.lastName"
                :value="item['@id']"
            />
          </el-select>
        </div>
        <p
            v-if="errorList && errorList.affectedEmployee"
            class="animated fadeIn invalid-feedback"
        >
          {{ errorList.affectedEmployee }}
        </p>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="equipmentTag">Etiqueta del equipo (si procede)</label>
          <el-input
              type="text"
              v-model="formModel.equipmentTag"
          />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="affectedUserEmail">Email empleado afectado</label>
          <el-input
              disabled
              type="text"
              v-model="formModel.affectedEmployeeEmail"
          />
        </div>
        <p
            v-if="errorList && errorList.affectedEmployee && errorList.affectedEmployeeEmail"
            class="animated fadeIn invalid-feedback"
        >
          {{ errorList.affectedEmployeeEmail }}
        </p>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="requestingEmployeeEmail">Email Solicitante</label>
          <el-input
              disabled
              type="text"
              v-model="formModel.requestingEmployeeEmail"
          />
        </div>
        <p
            v-if="errorList && errorList.requestingEmployee && errorList.requestingEmployeeEmail"
            class="animated fadeIn invalid-feedback"
        >
          {{ errorList.requestingEmployeeEmail }}
        </p>
      </el-col>
      <el-col :span="8">
        <ClockifyButton
            v-if="action !== 'create'"
            link=""
            :ticket="ticket"
            :clockifyProjectId="formModel.clockifyProjectId"
            :clockify-tag-id="formModel.clockifyTagId"
            @update-ticket="updateTicket"
        />
      </el-col>
    </el-row>
    <el-row v-if="formModel && formModel.project">
      <el-col :span="24" class="label-common">
        <div class="form-item">
          <label for="description">Descripción</label>
          <!-- <el-input
              v-model="formModel.description"
              :autosize="{ minRows: 8 }"
              type="textarea"
          /> -->
          <QuillEditor
            v-model:content="formModel.description"
            contentType="html"
            class="ql-editor quillNew"
            theme="snow"
            style="height: 200px;"
            />
        </div>
        <p
            v-if="errorList && errorList.description"
            class="animated fadeIn invalid-feedback"
        >
          {{ errorList.description }}
        </p>
      </el-col>
    </el-row>
    <el-row
        v-if="formModel && formModel.project"
        :gutter="20"
    >
      <el-col :span="12">
        <div class="form-item">
          <label>Adjuntos</label>
          <div
              class="border bg-white h-auto p-2 pl-5"
              style="border-radius: 16px;"
          >
            <span
                v-if="ticketDocument && ticketDocument.name"
                class="pointer text-primary"
                @click="showFile(ticketDocument)"
            >{{
                ticketDocument.name
              }}</span>
            <p v-else>
              No tiene adjuntos asociados
            </p>
          </div>
          <button
              class="btn btn_yellow btn-assign mt-2"
              @click="selectFile"
          >
            Seleccione...
          </button>
        </div>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="form-item">
              <label
                  for="priority"
                  class="w-100">Prioridad</label>
              <el-select
                  size="large"
                  filterable
                  v-model="formModel.ticketPriority">
                <el-option
                    :disabled="!canChangeStatus"
                    v-for="item in priorities"
                    :key="item.id"
                    :label="item.name"
                    :value="item['@id']"
                />
              </el-select>
              <p
                  v-if="errorList && errorList.ticketPriority"
                  class="animated fadeIn invalid-feedback"
              >
                {{ errorList.ticketPriority }}
              </p>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row
        v-if="formModel && action !== 'create'  && formModel.project"
        :gutter="20"
    >
      <el-col
          :span="12"
          v-if="hasRoleAdmin || isAssignedUser || !isRequestedUser">
        <SharedTicketComments
            title="Comentarios privados"
            :ticket="ticket"
            :is-private="true"
            placeholder="Escriba un comentario"
        />
      </el-col>
      <el-col :span="12">
        <SharedTicketComments
            title="Comentarios públicos"
            :ticket="ticket"
            :is-private="false"
            placeholder="Escriba un comentario"
        />
      </el-col>
    </el-row>
    <el-row v-if="formModel.project">
      <div class="d-flex justify-content-end align-items-center mt-lg-5">
        <button
            class="btn btn_yellow search mr-5"
            @click="router.go(-1)"
        >
          VOLVER
        </button>
        <button
            class="btn btn_darkpink search"
            @click.prevent="submit"
            :disabled="isSubmitting"
        >
          <!--          GUARDAR-->
          {{ isSubmitting ? 'Guardando...' : 'Guardar' }}
        </button>
      </div>
    </el-row>
  </div>
</template>

<script setup>

import {useStore} from "vuex";
import ProjectsService from "@/api/services/projects.service";
import TicketStatusService from "@/api/services/ticket.status.service";
import EmployeeService from "@/api/services/employee.service";
import TicketPriorityService from "@/api/services/ticket.priority.service";
import TicketTypeService from "@/api/services/ticket.type.service";
import TicketService from "@/api/services/ticket.service";
import SharedTicketComments from "@/components/shared/SharedTicketComments.vue";
import DepartmentService from "@/api/services/department.service";
import ClockifyButton from "@/components/shared/ClockifyButton.vue";
import {ElMessageBox} from "element-plus";
import {useRouter} from "vue-router";
import {computed, onMounted, ref, defineProps, defineEmits, watch} from "vue";
import {ElNotification} from "element-plus";
import TicketStatusHistoryService from "@/api/services/ticket.status.history.service";
import moment from "moment/moment";

const store = useStore();
const router = useRouter();

const props = defineProps({
  action: {
    type: String,
    required: true
  },
  ticket: {
    type: Object,
    required: true
  },
  isSubmitting: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits(['getTicket']);
const errorList = ref({});
// eslint-disable-next-line no-unused-vars
const params = ref({});
const formModel = ref(props.ticket || {
  project: null,
  status: null,
  department: null,
});
const users = ref([]);
const types = ref([]);
const projects = ref([]);
const states = ref([]);
const priorities = ref([]);
const optionsDepartment = ref([]);
// eslint-disable-next-line no-unused-vars
const statusSelected = ref(null);
const ticketDocument = ref(null);
// eslint-disable-next-line no-unused-vars
const errors = ref(null);
// eslint-disable-next-line no-unused-vars
const user = computed(() => store.state.user);
const hasRoleAdmin = computed(() => {
  const user = store.state.user;
  if (user) {
    return user.roles.includes('ROLE_ADMIN') || user.departmentManager;
  }
  return false;
});
const canChangeStatus = computed(() => {
  const user = store.getters.getUserLogged;
  const isAssigned = formModel.value.assignedEmployee && formModel.value.assignedEmployee.id === user.id;

  if (user) {
    return user.roles.includes('ROLE_ADMIN') || user.departmentManager || isAssigned || props.action !== 'create';
  }
  return false;
});

const startButtonLabel = computed(() => {
  if (formModel.value.status) {
    let id = formModel.value.status.split("/")[3];
    if (id < 3) {
      return "INICIAR";
    }
    if (id == 3) {
      return "FINALIZAR";
    }
  }
  return null;
});

const employees = computed(() => {
  if (formModel.value.department) {
    if (optionsDepartment.value.length === 0) {
      return users.value;
    }
    let department = optionsDepartment.value.find(item => item['@id'] === formModel.value.department)
    let name = department ? department.name : null;
    users.value.filter(user => user?.job?.department.name === name);
    if (hasRoleAdmin.value) {
      return users.value.filter(user => (user?.job?.department.name === name || user.id === store.getters.getUserLogged.id));
    } else {
      return users.value.filter(user => user?.job?.department.name === name);
    }
  }

  return users.value
});
const isRequestedUser = computed(() => {
  return formModel.value.requestingEmployee && store.getters.getUserLogged['@id'] === formModel.value.requestingEmployee;
});
const isAssignedUser = computed(() => {
  return formModel.value.assignedEmployee && store.getters.getUserLogged['@id'] === formModel.value.assignedEmployee;
});
// eslint-disable-next-line no-unused-vars
const userAffected = computed(() => {
  return formModel.value &&
      formModel.value.affectedEmployee &&
      formModel.value.affectedEmployee.name + " " + formModel.value.affectedEmployee.lastName;
});
// eslint-disable-next-line no-unused-vars
const userRegister = computed(() => {
  return formModel.value &&
      formModel.value.requestingEmployee &&
      formModel.value.requestingEmployee.name + " " + formModel.value.requestingEmployee.lastName;
});

watch(
    () => formModel.value.requestingEmployee,
    (newValue) => {
      if (newValue) {
        let filteredUser = users.value.find(item => item['@id'] === newValue)
        if (filteredUser) {
          formModel.value.requestingEmployeeEmail = filteredUser.email
          if (!formModel.value.affectedEmployee) {
            formModel.value.affectedEmployee = filteredUser['@id']
            formModel.value.affectedEmployeeEmail = filteredUser.email
          }
        }

      }
    }
);
watch(
    () => formModel.value.affectedEmployee,
    (newValue) => {
      if (newValue) {
        let filteredUser = users.value.find(item => item['@id'] === newValue)
        if (filteredUser) {
          formModel.value.affectedEmployeeEmail = filteredUser.email
        }

      }
    }
);


onMounted(async () => {
  await store.dispatch('setLoading', true)
  await Promise.all([
    getProjects(),
    getStates(),
    getPriorities(),
    getUsers(),
    getTypes(),
    getDepartmentsByClient()
  ]).then(async () => {
    await store.dispatch('setLoading', false)
    if (props.action === "update") {
      if (formModel.value && formModel.value.ticketDocuments && formModel.value.ticketDocuments.length > 0)
        await getTicketDocument(formModel.value.ticketDocuments[0]["@id"]);


      let onlyStatus = props.ticket.ticketStatusHistories.find(tsh => {
        if (!tsh.dateEnd) {
          return tsh.status['@id']
        }
      })

      if (!onlyStatus) {
        onlyStatus = props.ticket.ticketStatusHistories.find(tsh => {
          if (tsh.dateStart && tsh.dateEnd && tsh.status.name === "Cerrado") {
            return tsh.status['@id']
          }
        })
      }

      formModel.value.status = onlyStatus.status['@id']
      if (typeof formModel.value.ticketPriority === "object") {
        formModel.value.ticketPriority = formModel.value.ticketPriority['@id'];
      }
      if (typeof formModel.value.ticketType === "object") {
        formModel.value.clockifyTagId = formModel.value.ticketType.clockifyTagId;
        formModel.value.ticketType = formModel.value.ticketType['@id'];

      }
      if (typeof formModel.value.assignedEmployee === "object") {
        formModel.value.assignedEmployee = formModel.value.assignedEmployee['@id'];
      }
      if (props.ticket.affectedEmployee && typeof props.ticket.affectedEmployee === "object") {
        formModel.value.affectedEmployeeEmail = props.ticket.affectedEmployee.email
        formModel.value.affectedEmployee = props.ticket.affectedEmployee['@id'];
      }
      if (typeof formModel.value.requestingEmployee === "object") {
        formModel.value.requestingEmployeeEmail = formModel.value.requestingEmployee.email
        formModel.value.requestingEmployee = formModel.value.requestingEmployee['@id'];

      }
      if (typeof formModel.value.project === "object") {
        formModel.value.clockifyProjectId = formModel.value.project.clockifyProjectId;
        formModel.value.project = formModel.value.project['@id'];
      }

    } else {
      let now = moment(new Date());
      formModel.value.createdAt = now.format("YYYY-MM-DD")
      formModel.value.status = states.value.find(v => v.name === "Abierto");
      formModel.value.ticketPriority = priorities.value.find(v => v.name === "Alta")['@id'];
    }

  });
  await store.dispatch('setLoading', false)
})

const updateTicket = async () => {
  emits('getTicket')
}
const cleanMedia = async () => {
  await TicketService.deleteMediaTicket(ticketDocument.value.id);
  ticketDocument.value = null;
  formModel.value.ticketDocument = null;
  return true;
}
const confirmFileAttached = async () => {

  return ElMessageBox.confirm(
      '¿Deseas borrar el archivo?',
      'Confirmar',
      {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }
  )
      .then(async () => {
        await store.dispatch('setLoading', true)
        await cleanMedia();
        await store.dispatch('setLoading', false)
        //    notification.addNotification("Archivo borrado correctamente","success")
        return true;
      })
      .catch(() => {
        //   notification.addNotification("Ah cancelado, se mantiene el archivo adjunto actual","info")

        return false;
      });
}

const selectFile = async () => {
  //console.log("ARCHIVO",ticketDocument);
  if (ticketDocument.value && ticketDocument.value.name) {
    const result = await confirmFileAttached();
    if (!result) return;
  }
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.addEventListener("change", async function (e) {
    /*  var extn = e.target.files[0].type.split("/")[1];*/


    let size = e.target.files[0].size;
    let maxSize = 2097152; // 2097152 byte
    if (size < maxSize) {
      await store.dispatch('setLoading', true)
      const response = await TicketService.createMediaTicket(e.target.files[0]);
      const media = response.data;

      formModel.value.ticketDocuments.push(media["@id"]);
      ticketDocument.value = media;
      await store.dispatch('setLoading', false)
      ElNotification({
        type: 'success',
        message: 'Archivo cargado correctamente.',
        duration: 10000
      })
      //  notification.addNotification("Archivo cargado correctamente");
    } else {
      await store.dispatch('setLoading', false)
      ElNotification({
        type: 'error',
        message: 'No se puede cargar el Archivo porque excede el límite permitido.',
        duration: 10000
      })
      await store.dispatch('setLoading', false)
    }
    /*  _urlImage =URL.createObjectURL(e.target.files[0]); */
  });
  fileSelector.click();
  return false;
}
// eslint-disable-next-line no-unused-vars

const getDepartmentsByClient = async (clienId = 12) => {
  try {
    const response = await DepartmentService.getDepartmentsByClient(clienId);
    optionsDepartment.value = response.data["hydra:member"];
  } catch (e) {
    console.log(e)
  }

}
const getTicketDocument = async (url) => {
  const response = await TicketService.getTicketDocument(url);
  ticketDocument.value = response.data;

  return response.data["hydra:member"];
}
const showFile = (doc) => {
  if (doc.contentUrl) {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = process.env.VUE_APP_BASE_URI + "/" + doc.contentUrl;
    link.click();
  }

}
const getProjects = async () => {
  let params = {
    archived: false,
    itemsPerPage: 99999,
    page: 1,
    "order[name]": "ASC",
    // "client.name": "Undanet"
  }
  const response = await ProjectsService.getProjects(params);
  projects.value = response.data["hydra:member"];

  /*   params["client.name"] = 'etsa'
     const etsaResponse = await ProjectsService.getProjects(params);
     let etsa = etsaResponse.data["hydra:member"].filter(item => item.id === 662);
     if(etsa.length > 0){
       projects.value.push(etsa[0]);
     }*/

}
const getStates = async () => {
  const response = await TicketStatusService.getStates();
  states.value = response.data["hydra:member"];
  return response.data["hydra:member"];
}
const getTypes = async () => {
  const response = await TicketTypeService.getTypes();
  types.value = response.data["hydra:member"];
}

const getUsers = async (department = null) => {
  let params = {page: 1, itemsPerPage: 9999, active: 1}
  if (department) {
    params['department.name'] = department
  }
  const response = await EmployeeService.getEmployees(params);
  users.value = response.data["hydra:member"];
}
const getPriorities = async () => {
  const response = await TicketPriorityService.getPriorities();
  priorities.value = response.data["hydra:member"];
  return response.data["hydra:member"];
}

const startButton = async () => {
  if (formModel.value.status) {
    let id = parseInt(formModel.value.status.split("/")[3]);
    let message = '';
    if (id < 3) {
      formModel.value.status = states.value.find(v => v.name === "Trabajando")['@id'];
      message = 'Trabajando en el Ticket ' + formModel.value.tag + ' - ' + formModel.value.id;
    } else if (id === 3) {
      formModel.value.status = states.value.find(v => v.name === "Cerrado")['@id'];
      message = 'Ticket ' + formModel.value.tag + ' - ' + formModel.value.id + ' cerrado';
    } else {
      return;
    }
    await TicketStatusHistoryService.createStatusHistory({status: formModel.value.status, ticket: props.ticket['@id']})
        .then((res) => {
          formModel.value.status = res.data.status;
          console.log('res', res.data);
          ElNotification({
            type: 'success',
            title: 'Estado cambiado',
            message: message,
            duration: 10000
          });
        })
        .catch(() => {
          ElNotification({
            type: 'error',
            message: 'Error al cambiar el estado',
            duration: 10000
          });
        });
  }
}

const changeState = () => {
  ElMessageBox.confirm('Vas a cambiar el estado del ticket, ¿Estás seguro?', '', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancelar',
    type: 'warning',
  })
      .then(() => {
        TicketStatusHistoryService.createStatusHistory({status: formModel.value.status, ticket: props.ticket['@id']})
            .then((res) => {
              formModel.value.status = res.data.status;
              let message = 'Ticket ' + formModel.value.tag + ' ' + formModel.value.id + ' cambiado al estado ' + states.value.find(v => v['@id'] === res.data.status)['name'] + ' correctamente';
              ElNotification({
                type: 'success',
                title: 'Estado cambiado',
                // message: 'Ticket cambiado al estado '+  +' correctamente',
                message: message,
                duration: 10000
              });
            })
            .catch(() => {
              ElNotification({
                type: 'error',
                message: 'Error al cambiar el estado',
                duration: 10000
              });
            });
      })
      .catch(() => {
        ElNotification({
          type: 'info',
          message: 'Cambio de estado cancelado',
          duration: 10000
        });
      });
}

const assign = () => {
  if (!formModel.value.assignedEmployee) {
    formModel.value.assignedEmployee = store.getters.getUserLogged['@id'];
  }
  TicketService.updateTicket({assignedEmployee: typeof formModel.value.assignedEmployee == 'object' ? formModel.value.assignedEmployee['@id'] : formModel.value.assignedEmployee}, props.ticket.id)
      .then((res) => {
        ElNotification({
          type: 'success',
          title: 'Usuario asignado',
          message: res.data.assignedEmployee.fullName + ' asignado correctamente al ticket',
          duration: 10000
        })
        // notification.addNotification("Usuario asignado correctamente","success",10000)
      })
}
const submit = () => {
  errorList.value = {}
  let valid = true
  if (!formModel.value.project) {
    errorList.value.project = "El campo proyecto es requerido";
    valid = false
  }
  if (!formModel.value.department) {
    errorList.value.department = "El campo departamento es requerido";
    valid = false
  }
  if (!formModel.value.ticketType) {
    errorList.value.ticketType = "El campo tipo es requerido";
    valid = false
  }
  if (!formModel.value.ticketPriority) {
    errorList.value.ticketPriority = "El campo prioridad es requerido";
    valid = false
  }
  if (!formModel.value.description) {
    errorList.value.description = "El campo descripción es requerido";
    valid = false
  }
  if (!formModel.value.affectedEmployee) {
    errorList.value.affectedEmployee = "El usuario afectado es requerido";
    valid = false
  }
  if (!formModel.value.requestingEmployee) {
    errorList.value.requestingEmployee = "El usuario solicitante es requerido";
    errorList.value.requestingEmployeeEmail = "El email del usuario solicitante es requerido";
    valid = false
  }

  if (formModel.value.phone && formModel.value.phone.length !== 9) {
    errorList.value.phone = "El teléfono debe tener 9 dígitos";
    valid = false
  }
  if (!formModel.value.issue) {
    errorList.value.issue = "El asunto es requerido";
    valid = false
  }


  if (!valid) {
    ElNotification({
      type: 'error',
      message: 'No se pudo completar el proceso, ha de rellenar todos los campos marcados y requeridos'
    })
  } else {
    formModel.value.internal = true;
    emits('saveData', formModel.value);
    emits('submit');
  }

}


</script>

<style
    scoped
    lang="scss">
.btn-light {
  height: 42px !important;
}

.btn-assign {
  height: 42px;
  color: #474CE6;
  min-width: 200px;
  font-weight: 400;
  font-size: 20px;
  width: 300px;
  font-family: "Montserrat";
}

.search {
  height: 42px;
  width: 300px;
}

.wback {
  background-image: url('../../assets/images/ticket-back.png');
  background-repeat: no-repeat;
  background-size: contain;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 8px;
  font-family: Montserrat;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
}

label.checkbox-label input[type=checkbox] {
  position: relative;
  vertical-align: middle;
  bottom: 1px;
}

</style>
