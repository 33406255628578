<script setup>

import TicketService from "@/api/services/ticket.service";
import Notification from "@/utils/plugins/NotificationService";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import moment from "moment/moment";
import {onMounted, ref, defineEmits, defineProps, watch} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import stateColors from "@const/stateColors";
import priorityColors from "@const/priorityColors";

const store = useStore();
const router = useRouter();
// eslint-disable-next-line no-unused-vars
const emits = defineEmits(['totalTicket']);

const tickets = ref([]);
const clientOrder = ref(null);
const typeOrder = ref(null);
const stateOrder = ref(null);

const descriptionOrder = ref(null);
const totalResults = ref(0);
// const itemsPerPage = ref({id: 0, name: '5', value: 5});
const itemsPerPage = ref(5);
const page = ref(1);
const type = ref(null);
const priority = ref(null);
const tag = ref(null);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);

const props = defineProps({
  refreshData: {
    type: Boolean,
    required: false
  },
  filters: {
    type: Object,
    required: false
  }
})
onMounted(async () => {
  await getTickets()
})
watch(() => props.refreshData, async () => {
  await getTickets()
})
watch(() => props.filters, async () => {
  await getTickets()
})


/* const client = (ticket) => {
     return ticket.affectedUser ? ticket.affectedUser.name : (ticket.requestUser ? ticket.requestUser.name :'')
   }*/
const gotoEdit = (id) => {
  router.push({name: 'update_internal_ticket', params: {id: id}})
}
const getTickets = async () => {
  const params = {
    // itemsPerPage: itemsPerPage.value.value,
    itemsPerPage: itemsPerPage.value,
    page: page.value,
    'order[createdAt]': 'DESC',
    internal: true,
    ...props.filters
  }
  if (clientOrder.value) {
    params['order[name]'] = clientOrder.value;
  }
  if (stateOrder.value) {
    params['order[state]'] = stateOrder.value;
  }
  if (descriptionOrder.value) {
    params['order[description]'] = descriptionOrder.value;
  }
  if (typeOrder.value) {
    params['order[type]'] = typeOrder.value;
  }

  if (type.value) {
    params['ticketType.id'] = type.value;
  }

  if (priority.value) {
    params['ticketPriority.id'] = priority.value;
  }

  if (tag.value) {
    params.tag = tag.value;
  }

  if (selectedFromDate.value && selectedToDate) {
    params["createdAt[strictly_before]"] = selectedToDate.value;
    params["createdAt[strictly_after]"] = selectedFromDate.value;
  }

  let id = store.getters.getUserLogged.id
  // params['requestingUser.id'] = store.state.user.id
  await store.dispatch('setLoading', true)
  try {
    // primera petición con el usuario logeado
    const response1 = await TicketService.getRequestedTickets(id, params)
    const ticketsData = response1.data['hydra:member'];
    const total = response1.data["hydra:totalItems"];

    // si el id es 41 o 38, realiza una segunda petición con el otro id
    let extraTicketsData = [];
    let extraTotal = 0;
    if (id === 41 || id === 38) {
      id = id === 41 ? 38 : 41;
      const response2 = await TicketService.getRequestedTickets(id, params);
      extraTicketsData = response2.data['hydra:member'];
      extraTotal = response2.data["hydra:totalItems"];
    }

    // acumulamos todos los resultados
    tickets.value = [...ticketsData, ...extraTicketsData].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    totalResults.value = total + extraTotal;
    emits('totalTicket', totalResults.value);
  } catch (e) {
    Notification.addNotification('Error al obtener listado de tickets solicitados.', 'error', 6000);
  } finally {
    await store.dispatch('setLoading', false);
  }

  // TicketService.getRequestedTickets(params)
  //     .then((response) => {
  //       tickets.value = response.data['hydra:member'];
  //       totalResults.value = response.data["hydra:totalItems"];
  //       emits('totalTicket', totalResults.value);
  //     })
  //     .catch(() => {
  //       Notification.addNotification('Error al obtener listado de tickets.', 'error', 6000);
  //     }).finally(() => {
  //       store.dispatch('setLoading', false);
  //     });

}
const formatDateLocale = (value, loc = 'es') => {
  if (loc) {
    loc = 'es';
  }
  moment.updateLocale('es', {
    monthsShort: [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ]
  });
  if (value) {
    return moment(value).local(loc).format('DD/MM/YYYY HH:mm:ss');
  } else {
    return null;
  }
}

const typeColor = (type) => {
  if (type === 'Incidencia')
    return {color: '#F9156F', label: 'INCIDENCIA'};
  else if (type === 'Solicitud de servicio') {
    return {color: '#4AABC3', label: 'SERVICIO'};
  } else if (type === 'Solicitud de soporte (Duda)') {
    return {color: '#919191', label: 'SOPORTE'};
  }
  return 'grey'
}

const changeOrderType = () => {

}

</script>
<template>
  <div class="w-100">
    <el-row>
      <div
          v-if="tickets && tickets.length>0"
          class="table pt-0"
      >
        <table>
          <thead>
          <tr>
            <th style="width: 210px;">
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="">CÓDIGO
                  <font-awesome-icon
                      v-if="orderType === 'ASC'"
                      icon="sort-alpha-up"/>
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"/>
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="">TIPO
                  <font-awesome-icon
                      v-if="orderType === 'ASC'"
                      icon="sort-alpha-up"/>
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"/>
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center pointer">
                <div>
                  <img
                      width="21"
                      height="20"
                      src="@/assets/icons/group.svg"
                      alt=""> DESCRIPCIÓN
                  <font-awesome-icon
                      v-if="orderDescription === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderDescription === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th style="width: 230px;">
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderType()"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/calendar-blue.svg"
                      alt="fecha inicio"
                  >FECHA INICIO
                  <font-awesome-icon
                      v-if="typeOrder === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="typeOrder === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th style="width: 210px;">
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderType()"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/calendar-blue.svg"
                      alt="dias abierta"
                  >DIAS ABIERTA
                  <font-awesome-icon
                      v-if="typeOrder === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="typeOrder === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
<!--            <th>-->
<!--              <div-->
<!--                  class="d-flex justify-content-start align-items-center pointer"-->
<!--                  @click="changeOrderType()"-->
<!--              >-->
<!--                <div>-->
<!--                  <img-->
<!--                      width="21"-->
<!--                      height="20"-->
<!--                      class="mr-2"-->
<!--                      src="@/assets/icons/group.svg"-->
<!--                      alt="estado"-->
<!--                  >PRIORIDAD-->
<!--                  <font-awesome-icon-->
<!--                      v-if="orderType === 'ASC'"-->
<!--                      icon="sort-alpha-up"-->
<!--                  />-->
<!--                  <font-awesome-icon-->
<!--                      v-else-if="orderType === 'DESC'"-->
<!--                      icon="sort-alpha-down"-->
<!--                  />-->
<!--                  <font-awesome-icon-->
<!--                      v-else-->
<!--                      icon="sort"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </th>-->
            <th style="width: 160px;">
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderType()"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="estado"
                  >ESTADO
                  <font-awesome-icon
                      v-if="orderType === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/user.svg"
                      alt="">ASIGNADO A
                  <font-awesome-icon
                      v-if="orderStatus === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderStatus === 'DESC'"
                      icon="sort-alpha-down"/>
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>

          </tr>
          </thead>
          <tbody v-if="tickets && tickets.length && !store.state.loading">
          <tr
              v-for="ticket in tickets"
              :key="ticket.id"
          >
            <td>
              <el-tooltip
                  placement="top"
                  effect="light"
              >
                <template #content>
                  <span :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}"> Prioridad {{
                      ticket.ticketPriority.name
                    }} </span>
                </template>
                <div
                    class="d-flex justify-content-start align-items-center"
                    :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}">
                  [{{ ticket.tag }}] - {{ ticket.id }}
                  <font-awesome-icon
                      class="ms-2 fa-2x"
                      :icon="priorityColors.getIcon(ticket.ticketPriority.name)"
                      :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}"/>
                </div>
              </el-tooltip>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                <div
                    class="type-color"
                    :style="{backgroundColor: typeColor(ticket.ticketType?.name).color}"
                />
                {{ typeColor(ticket.ticketType?.name).label }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ ticket.issue }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ formatDateLocale(ticket.createdAt) }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ ticket.daysOpen }}
              </div>
            </td>
<!--            <td>-->
<!--              <div-->
<!--                  class="flex-row justify-content-between text-uppercase"-->
<!--                  :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}">-->
<!--                {{ ticket.ticketPriority.name }}-->
<!--              </div>-->
<!--            </td>-->
            <td>
              <div
                  class="flex-row justify-content-between"
                  :style="{color: stateColors.getColor(ticket.status)}">
                {{ ticket.status }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-between align-items-center">
                <span v-if="ticket.assignedEmployee">{{ ticket.assignedEmployee.fullName }}</span>
                <span v-else>SIN ASIGNAR</span>
                <shared-button-plus
                    @onClick="gotoEdit(ticket.id)"
                    :title="'Editar ticket'"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p
            class="alert alert-info mt-3"
            v-if="!store.state.loading">
          No tiene tickets solicitados actualmente
        </p>
      </div>
    </el-row>
    <el-row v-if="tickets.length">
      <SharedPagination
          v-if="tickets && totalResults > itemsPerPage"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          @change="page = $event;getTickets();"
      />
    </el-row>
  </div>
</template>


<style scoped>
.type-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
