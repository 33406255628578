<template>
  <div class="container m-0 p-0 mt-3 pb-5">
    <!-- <textarea
      v-model="formModel.comment"
      class="border-none"
      :placeholder="placeholder"
    /> -->
    <QuillEditor
      v-model:content="formModel.comment"
      contentType="html"
      @text-change="onTextChanged"
      theme="snow"
      class="quillNew"
    />
    <div class="mt-3">
      <button
          :disabled="!formModel.comment || !showCommentButton"
          class="btn btn_red btn-comment float-right"
          style="width: 170px;"
          @click="submit()"
      >
        <img
          width="21"
          height="25"
          class="mr-2"
          src="@/assets/icons/message-white.svg"
          alt=""
        >COMENTAR
      </button>
    </div>
  </div>
</template>

<script>

import TicketService from "@/api/services/ticket.service";
import notification from "@/utils/plugins/NotificationService";

export default {
  name: "SharedCommentForm",

  props: {
    placeholder:{
      type:String,
    },
    isPrivate: {
      type: Boolean,
      required: true
    },
    ticket: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
       formModel: {
         comment: null,
         ticket: this.ticket['@id'],
         authorEmployee: this.$store.state.user['@id'],
         authorExtranetUser: null,
         private: this.isPrivate,
       },
       showCommentButton: false,
    }
  },
  methods:{
    submit(){
      if(!this.formModel.comment || this.formModel.comment.length < 2){
        notification.addNotification("No puede enviar un comentario en blanco","error",10000)
        return
      }
      TicketService.createComment(this.formModel).then(()=>{
        this.formModel.comment = "\n"
        this.$emit('refresh')
      })
      notification.addNotification("Comentario creado correctamente","success",10000)
    },
    onTextChanged(){
      let temp = this.removeHtmlTags(this.formModel.comment);      
      if(temp.length>=2){
        this.showCommentButton = true;
      }else{
        this.showCommentButton=false;
      }
      if (temp.length<1) {
        this.formModel.comment = "";
      }
    },
    removeHtmlTags(input) {
    return input.replace(/<[^>]*>/g, '');
    }

  }
};
</script>
<style lang="scss" scoped>
 textarea {
   border-radius: 0;
   width: 100%;
   border: none!important;
   background-color: #EDF2FA;
   padding: 10px;
   font-size: 20px;
 }
 .btn-comment{
   height: 45px;
   color: #FFFFFF;
   min-width: 200px;
   font-size: 20px;
   width: 100%;
   font-weight: 500;
 }


.quillNew{
  color: red !important;
}


</style>
