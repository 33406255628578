<template>
  <div>
    <header-title title="Comunicación"/>
    <div class="d-flex flex-row justify-content-start">
      <img
          src="../../assets/icons/camiseta-icon.svg"
          class="icon-undanitas"
          width="30"
          alt=""
          srcset=""
      >

      <div class="title-text">
        Undanitas
      </div>
    </div>
    <el-row>
      <el-card
          class="card-search w-100"
          body-style="padding:0"
      >
        <div class="row">
          <div class="col-md-4">
            <div class="form-item w-100">
              <label
                  class="w-100"
              >Empleado</label>
              <el-select
                  v-model="employee"
                  placeholder="Seleccione empleado"
                  class="w-100"
                  filterable
                  clearable
                  @change="filterUndanitas"
              >
                <el-option
                    v-for="item in optionsEmployee"
                    :key="item['@id']"
                    :label="item.fullName"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-item w-100">
              <label for="title">T&iacute;tulo</label>
              <el-input
                  id="title"
                  v-model="title"
                  class="search-input"
                  placeholder="Ingrese título"
                  clearable
                  @change="filterUndanitas"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-item">
              <label
                  for="id-status"
                  class="w-100"
              >Estado</label>
              <el-select
                  id="id-status"
                  v-model="status"
                  placeholder="Seleccione estado"
                  class="w-100"
                  filterable
                  clearable
                  @change="filterUndanitas"
              >
                <el-option
                    v-for="item in optionsStatus"
                    :key="item['@id']"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-item date-picker w-100">
              <label class="w-100">Desde</label>
              <el-date-picker
                  class="w-100 pr-5"
                  v-model="selectedFromDate"
                  type="date"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{ firstDayOfWeek: 1 }"
                  @change="filterUndanitas"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-item date-picker">
              <label class="w-100">Hasta</label>
              <el-date-picker
                  v-model="selectedToDate"
                  class="w-100 pr-5"
                  type="date"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{ firstDayOfWeek: 1,disabledDate:(time)=>disabledEndDate(time,selectedFromDate)}"
                  @change="filterUndanitas"
              />
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-end align-items-end">
            <button
                class="btn btn_red btn-search"
                style="color: white;"
                @click="$router.push({name:'new_undanitas'})"
            >
              NUEVA
            </button>
            <button
                class="btn btn_yellow btn-search ml-2"
                @click="filterUndanitas()"
            >
              BUSCAR
            </button>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <div class="table table-users">
        <table>
          <thead>
          <tr>
            <th>
              <div class="d-flex justify-content-start align-items-center">
                <img
                    src="@/assets/icons/calendar-icon.svg"
                    width="20"
                    height="20"
                    alt="calendar-icon"/>
                <div>FECHA</div>
              </div>
            </th>
            <th>
              <div class="d-flex flex-row  justify-content-start align-items-start">
                <img
                    src="@/assets/icons/user.svg"
                    width="20"
                    height="20"
                    alt="user-icon"/>
                <div class="ml-2">
                  EMPLEADOS
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center">
                <!-- <img src="../../assets/icons/undanews.png" class="icon-search" alt="" srcset=""> -->
                <img
                    src="@/assets/icons/unda-icon.svg"
                    width="20"
                    height="20"
                    alt="user-icon"/>
                <div>TITULO</div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center">
                <!--  <img src="../../assets/icons/undanews.png" class="icon-search" alt="" srcset=""> -->
                <img
                    src="@/assets/icons/unda-icon.svg"
                    width="20"
                    height="20"
                    alt="user-icon"/>
                <div>ESTADO</div>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="und in undanitas"
              :key="und.id"
          >
            <td>
              {{ formatDate(und.timestamp) }}
            </td>
            <td>
              <CellEmployee
                  :employee="selectEmployee(und.employee)"
                  :without-link="true"
                  :is-show-last-name="false"
              />
            </td>
            <td>
              <router-link :to="{name: 'update_undanitas', params: { id: und.id }}">
                {{ und.title }}
              </router-link>
            </td>
            <td>
              {{ selectStatus(und.newsStatus) }}
            </td>
            <td>
              <div class="d-flex align-center align-items-center">
                <div
                    class="pointer"
                    @click="goUpdate(und.id)"
                >
                  <img
                      width="25"
                      src="../../assets/icons/edit_undanew.png"
                      alt=""
                      srcset=""
                  >
                </div>
                <div class="m-4">
                  <font-awesome-icon
                      width="25"
                      icon="trash"
                      class="pointer"
                      @click="deleteUndanitas(und)"
                  />
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-row>
    <el-row>
      <SharedPagination
          v-if="undanitas && totalResults > itemsPerPage"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          @change="page = $event;filterUndanitas();"
      />
    </el-row>
    <el-row class="mt-5">
      <button
          class="btn btn_yellow btn-search float-right w-25"
          @click="goBack()"
      >
        VOLVER
      </button>
    </el-row>
  </div>
</template>
<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import EmployeeServices from "@/api/services/employee.service.js";
import Undanitas from "@/api/services/undanitas.service.js";
import CellEmployee from "@/components/shared/CellEmployee.vue";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import moment from "moment";
import {h, onMounted, ref, shallowRef} from "vue";
import {useRouter} from "vue-router";

const itemsPerPage = ref(10)
const page = ref(1)
const totalResults = ref(0)
const title = ref('')
const optionsEmployee = ref([])
const undanitas = ref([])
const employee = ref('')
const optionsStatus = ref([])
const status = ref('')
const selectedFromDate = ref('')
const selectedToDate = ref('')

const router = useRouter()

onMounted(() => {
  getEmployees()
  getStatus()
  filterUndanitas()
})


const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
const formatDate = (value) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY");
  }
  return "";
}

const deleteUndanitas = (und) =>
{
  Undanitas.delete(und.id).then(() => {
    filterUndanitas();
  });
}

const goUpdate = (id) =>
{
  router.push({name: 'update_undanitas', params: {id}})
}

const goBack = () =>
{
  router.go(-1);
}

// const selectStatus = (status) =>
// {
//    console.log("SSS",status);
//   const statusUnd = optionsStatus.value.find(v => v['@id'] === status['@id']);
//   /* console.log("SSS",optionsStatus); */
//   return statusUnd && statusUnd.name && statusUnd.name;
// }

const selectStatus = (status) => {
  //  console.log("SSS",status);
  const statusUnd = optionsStatus.value.find(v => v['@id'] === status['@id']);
  //console.log("SSS",statusUnd);
  return statusUnd && statusUnd.name && statusUnd.name;
}

const selectEmployee= (emp) =>
{
  if (!emp) return null;
  return optionsEmployee.value.find(v => v['@id'] === emp['@id']);

}

const filterUndanitas = () =>
{
  const params = {
    itemsPerPage: itemsPerPage.value,
    page: page.value
  }
  if (title.value) {
    params.title = title.value;
  }
  if (employee.value) {
    params['employee.id'] = employee.value;
  }
  if (status.value) {
    console.log("STATUS", status.value);
    params['newsStatus.id'] = status.value
  }
  if (selectedToDate.value && selectedFromDate.value) {
    params['timestamp[before]'] = selectedToDate.value//moment(selectedFromDate,"YYYY-MM-DD");
    params['timestamp[after]'] = selectedFromDate.value//moment(selectedToDate,"YYYY-MM-DD");
  } else if (selectedFromDate.value) {
    params['timestamp[after]'] = selectedFromDate.value//moment(selectedFromDate,"YYYY-MM-DD");
  } else if (selectedToDate.value) {
    params['timestamp[before]'] = selectedToDate.value//moment(selectedToDate,"YYYY-MM-DD");
  }
  params["order[timestamp]"] = "desc";
  Undanitas.get(params).then(resp => {
    undanitas.value = resp.data['hydra:member'];
    totalResults.value = resp.data["hydra:totalItems"];
  });
}

const disabledEndDate = (date, departureDate) =>
{
  //console.log("date",date);
  //console.log("depatura",departureDate);
  let flag = false;
  // If departureDate then return valid dates after departureDate
  if (departureDate) {
    flag = moment(date.getTime()) < moment(departureDate)

  } else {
    // If !departureDate then return valid dates after today
    flag = moment(date.getTime()) < moment.now();
    /* return date.getTime() < Date.now() */
  }
  //console.log("BANDERA",flag);
  return flag;
}
const getEmployees = () =>
{
  EmployeeServices.getEmployees({itemsPerPage: 9999, page: 1, active: true})
      .then((resp) => {
    //console.log("Select emp",resp.data['hydra:member'])
    optionsEmployee.value = resp.data['hydra:member']
    });
}

const getStatus = () =>
{
  Undanitas.getStatus({itemsPerPage: 9999, page: 1}).then((resp) => {
    //console.log("Select emp",resp.data['hydra:member'])
    optionsStatus.value = resp.data['hydra:member'];
  });
}
// const getUndanitas = () =>
// {
//   Undanitas.get({itemsPerPage: itemsPerPage, page: page}).then((resp) => {
//     console.log("Select undanes", resp.data['hydra:member'])
//     optionsStatus = resp.data['hydra:member'];
//   });
// }

</script>
<style
    lang="scss"
    scoped>
.icon-search {
  width: 15%;
}

#icon-user {
  fill: $bg-blue;
  width: 1.8em;
}

#icon-calendar {
  fill: $bg-blue;
  height: 40px;
  width: 36px;
}

.icon-undanitas {
  width: 4%;
}

.card-search {
  border-radius: 15px;
  height: 285px;
  background: url("../../assets/images/camiseta.png"), white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -85px;
  overflow: visible !important;
}

.table-users {
  table {

    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}

.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}
</style>
