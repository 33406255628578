<template>
  <div>
    <header-title title="Talento" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/heart-icon.svg" width="50" alt="">
      <div class="title-text ml-4">
        Beneficios de empleados
      </div>
    </div>
    <el-row>
      <el-card
        class="card-search w-100"
        body-style="padding:0"
      >
        <div class="row  mx-4">
          <div class="col-md-6">
            <div class="d-flex flex-column flex-nowrap mt-4 label-common">
              <div class="form-item">
                <label for="title">T&iacute;tulo</label>
                <el-input
                    id="title"
                    v-model="title"
                    placeholder="Ingrese título"
                    clearable
                    @change="search"
                />
              </div>

            </div>
          </div>
          <div class="col-md-3">
            <div class="d-flex flex-nowrap flex-column mt-4 label-common">
              <div class="form-item date-picker">
              <label for="id-improvements"  class="w-100">Validez Desde</label>
              <el-date-picker
                v-model="fromDate"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                :prefix-icon="customPrefix"
                :picker-options="{ firstDayOfWeek: 1 }"
                @change="search"
              />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="
                d-flex
                flex-nowrap flex-column
                mt-4
                label-common"
            >
              <div class="form-item date-picker">
              <label
                for="id-status"
                class="w-100"
              >Hasta</label>
              <el-date-picker
                v-model="toDate"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                :prefix-icon="customPrefix"
                :picker-options="{ firstDayOfWeek: 1,disabledDate:(time)=>disabledEndDate(time,fromDate)}"
                @change="search"
              />
              </div>
            </div>
          </div>
        </div>
        <div
          class="row mx-4  mb-4">
          <div class="col-md-6 pr-4">
            <div class="d-flex flex-column flex-nowrap w-100 label-common">
              <label for="title">Estado</label>
              <el-radio-group
                v-model="status"
                class="ml-4"
              >
                <el-radio
                  label="active"
                  size="large"
                >
                  Activo
                </el-radio>
                <el-radio
                  label="noactive"
                  size="large"
                >
                  No activo
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="col-md-6 align-items-center justify-content-end ml-auto d-flex">
            <div class="">
              <button
              class="btn btn_red btn-search "
              @click="newBenefit()"
              >
              NUEVA
            </button>
          </div>
          <div class="">
            <button
            class="btn btn_yellow btn-search"
            @click="search()"
            >
            Buscar
          </button>
        </div>
      </div>
        </div>
      </el-card>
    </el-row>

    <el-row>
      <div class="table table-users">
        <table>
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-content-start align-items-center">
                  <img src="@/assets/icons/heart-icon.svg" width="25" alt="">
                  <div class="ml-2">
                    TITULO
                  </div>
                </div>
              </th>
              <th>
                <div class="d-flex flex-row  justify-content-start align-items-center">
                  <img src="@/assets/icons/heart-icon.svg" width="25" alt="">
                  <div class="ml-2">
                    EMPRESA
                  </div>
                </div>
              </th>

              <th>
                <div class="d-flex justify-content-start align-items-center">
                  <img src="@/assets/icons/heart-icon.svg" width="25" alt="">
                  <div class="ml-2">
                    ESTADO
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="benf in benefits"
              :key="benf.id"
            >
              <td>
                <div class="link-name">
                  <router-link :to="{name: 'update-benefit', params: { id: benf.id }}">
                    {{ benf.title }}
                  </router-link>
                </div>
              </td>
              <td>
                {{ benf.company }}
              </td>
              <td>
                {{ benf.active ? 'ACTIVO':'NO ACTIVO' }}
              </td>
              <td>
                <div class="d-flex align-center align-items-center">
                  <div
                    class="pointer"
                    @click="goUpdate(benf.id)"
                  >
                    <img
                        width="25"
                      src="../../assets/icons/edit_undanew.png"
                      alt=""
                      srcset=""
                    >
                  </div>
                  <div class="m-4">
                    <font-awesome-icon
                      icon="trash"
                      width="25"
                      class="pointer"
                      @click="deleteBenefit(benf)"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-row>
  </div>
</template>
<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

import Services from "@/api/services/benefit";
import moment from "moment/moment";
import {h, onMounted, ref, shallowRef} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();
const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})

const itemsPerPage = ref(10);
const page = ref(1);
const title = ref('');
// eslint-disable-next-line no-unused-vars
const description = ref('');
const status = ref('');
// eslint-disable-next-line no-unused-vars
const optionsStatus = ref([]);
const benefits = ref([]);
const fromDate = ref('');
const toDate = ref('');
// eslint-disable-next-line no-unused-vars
const contact = ref('');

  onMounted(()=>{
    filter();
   /* Promise.all([getEmployees(), getStatus(),getDepartments()])
        .then(()=>filterSuggestions());*/
    /* getProjectManager(); */
  })

   const deleteBenefit = (b) =>{
      Services.delete(b.id).then(()=>{
        filter();
      })
    }
   const disabledEndDate = (date,departureDate) =>{

      let flag = false;
      // If departureDate then return valid dates after departureDate
      if (departureDate) {
        flag = moment(date.getTime()) < moment(departureDate)

      } else {
        // If !departureDate then return valid dates after today
        flag = moment(date.getTime()) < moment.now();
        /* return date.getTime() < Date.now() */
      }

      return flag;
    }
    const search = () =>{
      filter();
    }
    const filter = () =>{
      const params = {
        itemsPerPage:itemsPerPage.value,
        page:page.value,
        'order[fromDate]':'DESC',

      }
      if(title.value)
        params.title = title.value;

      if(status.value){
         params.active = status.value === 'active';
      }
      if(fromDate.value && toDate.value){
        params['fromDate[after]']=fromDate.value//moment(selectedFromDate,"YYYY-MM-DD");
        params['toDate[before]']=toDate.value//moment(selectedToDate,"YYYY-MM-DD");
      }

      Services.get(params)
          .then((resp)=>{
            console.log("Benefits",resp);
            benefits.value = resp.data['hydra:member'];
          })
    }
// eslint-disable-next-line no-unused-vars
    const formatDateTime =(value) => {
      if (value) {
        return moment(value).format('DD/MM/YYYY HH:mm');
      }
      return '';
    }
const goUpdate =(id) => {
      router.push({name:'update-benefit',params:{id}})
    }
const newBenefit =() => {
      router.push({name:'create-benefits'});
    }

</script>
<style  lang="scss" scoped>

.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
      #icon-search {
        fill: $bg-blue;
        width: 20px;
      }
      #icon-user{
        fill: $bg-blue;
        width: 1.6em;
      }
    }
    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
.btn-search {
  &.btn_yellow{
    color: $bg-blue;
  }
  &.btn_red{
    color:white;
  }
  width: 20em;
  height: 3em;

  font-weight: bolder;
  text-transform: uppercase;
  width: 200px;
  height: 40px;
}
#icon-heart {
  fill: $bg-blue;
  width: 3%;
}
</style>
