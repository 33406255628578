<template>
  <div
      v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')"
      class="holidays"
  >
    <HeaderTitle title="Talento"/>
    <div class="d-flex flex-row justify-content-start">
      <img
          src="@/assets/icons/hollidays.svg"
          alt=""
      >
      <div class="title-text ml-4">
        Vacaciones
      </div>
    </div>
    <el-row class="mt-2">
      <el-card
          class="card-search card-abs w-100 wback"
          body-style="padding:0"
      >
        <div class="container-holidays">
          <!-- Nombre -->
          <div class="row">
            <div class="col-md-4">
              <div class=" form-item label-common">
                <label class="w-100">Nombre</label>
                <el-input
                    v-model="employeeName"
                    class="search-input"
                    placeholder="Nombre"
                    clearable
                    @change="search"

                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-item label-common">
                <label class="w-100">Departamento</label>
                <el-select
                    v-model="selectedDepartmentFilter.value"
                    placeholder="Todos"
                    clearable
                    filterable
                    @change="search"
                >
                  <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-item label-common">
                <label class="w-100">Estado</label>
                <el-select
                    v-model="selectedStatusFilter.value"
                    placeholder="Todos"
                    clearable
                    filterable
                    @change="search"
                >
                  <el-option
                      v-for="item in statusOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </div>
            </div>
          </div>

          <!-- Fechas y estado -->
          <div class="">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class=" form-item  date-picker  label-common">
                      <label class=" w-100 mb-2">Desde</label>
                      <el-date-picker
                          :picker-options="{ firstDayOfWeek: 1 }"
                          :prefix-icon="customPrefix"
                          @change="search();"
                          class="w-100"
                          format="DD/MM/YYYY"
                          type="date"
                          v-model="selectedFromDate"
                          value-format="YYYY-MM-DD"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class=" form-item  date-picker  label-common">
                      <label class="w-100 mb-2">Hasta</label>
                      <el-date-picker
                          :default-value="defaultToDate"
                          :disabled-date="disabledDate"
                          :picker-options="{ firstDayOfWeek: 1 }"
                          :prefix-icon="customPrefix"
                          @change="search()"
                          class="w-100"
                          format="DD/MM/YYYY"
                          type="date"
                          v-model="selectedToDate"
                          value-format="YYYY-MM-DD"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex justify-content-end align-content-end">
                  <div class="col-md-7">
                    <button
                      class="btn btn_yellow btn-search btn-new"
                      @click="search()"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <button
          style="width: 300px;margin-top: 15px;"
          type="button"
          class="btn btn_red"
          @click="changeBetweenHolidaysOrGantt"
      >
        {{ textButton }}
      </button>

      <div
          class="total-vacations"
      >
        {{ totalVacations }} días de vacaciones aprobadas
      </div>
    </el-row>


    <!-- Tabla -->
    <div
        v-if="!showGantt"
        class="table"
    >
      <div v-if="holidays && holidays.length > 0">
        <table>
          <thead>
          <tr>
            <th class="text-left">Nombre</th>
            <th>Solicitud</th>
            <th>Desde</th>
            <th>Hasta</th>
            <th>Ejercicio</th>
            <th>Días</th>
            <th>Responsable</th>
            <th style="min-width: 150px;">Estado</th>
          </tr>
          </thead>
          <tbody v-if="holidays && holidays.length">
          <tr
              v-for="(holiday, index) in holidays"
              :key="index"
          >
            <td
                class="pointer"
                @click="openHolidayDetails(holiday)">
              <div class="row-table-image-name-link justify-content-start">
                <EmployeeAvatar :employee="holiday.employee"/>
                {{ holiday.employee.name }} {{ holiday.employee.lastName }}
              </div>
            </td>
            <td
                class="pointer"
                @click="openHolidayDetails(holiday)"
            >
              {{ formatDateTime(holiday.timestamp) }}
            </td>
            <td
                class="pointer"
                @click="openHolidayDetails(holiday)"
            >
              {{ formatDate(holiday.fromDate) }}
            </td>
            <td
                class="pointer"
                @click="openHolidayDetails(holiday)"
            >
              {{ formatDate(holiday.toDate) }}
            </td>
            <td
                class="pointer"
                @click="openHolidayDetails(holiday)"
            >
              {{ holiday.yearExercise }}
            </td>
            <td
                class="pointer"
                @click="openHolidayDetails(holiday)"
            >
              {{ holiday.daysConsumed }}
            </td>
            <td
                class="pointer"
                @click="openHolidayDetails(holiday)"
            >
              {{
                (holiday.employee.teamManager != null ? holiday.employee.teamManager.name + ' ' +
                    holiday.employee.teamManager.lastName : '--')
              }}
            </td>
            <td>
              <div class="holiday-select form-item">
                <el-select
                    v-model="holiday.approved"
                    placeholder="Estado"
                    :class="statusClassById(holiday.approved)"
                    @change="specificHolidayStatusChange(index)"
                >
                  <el-option
                      v-for="item in statusOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >

                    <span :class="statusClass(item.name)">{{ item.name }}</span>

                  </el-option>
                  <template #tag>
                    <el-tag :style="{color: 'blue'}"/>
                  </template>
                </el-select>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado vacaciones según los filtros especificados...</h5>
      </div>
      <SharedPagination
          v-if="holidays && totalResults > itemsPerPage"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          @change="page = $event; getHolidays()"
      />
    </div>
    <div v-else>
      <holiday-gantt
          :vacation-planning="vacationPlanning.vacationPlanning"
          :from-date="vacationPlanning.fromDate"
          :to-date="vacationPlanning.toDate"
      />
    </div>


    <!-- Ventana de confirmación de cambio de estado de unas vacaciones -->
    <SharedDialog
        v-if="showStatusConfirmationModal"
        :title="'¿Cambiar el estado de las vacaciones?'"
        :message="statusConfirmationMsg"
        :close-icon="false"
        @confirm="acceptStatusConfirmationModal()"
        @decline="closeStatusConfirmationModal()"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedDialog from "@/components/shared/SharedDialog.vue";
import HolidayGantt from "./HolidayGantt";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import HolidayService from '@/api/services/holiday.service';
import Common from '@/api/services/common.service';
import ResourceService from "@/api/services/resource.service";

import moment from "moment";
import {computed, h, onMounted, ref, shallowRef} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import EmployeeAvatar from "@/components/shared/EmployeeAvatar.vue";
import {ElNotification} from "element-plus";
import message from "@const/message";

const store = useStore();
const router = useRouter()


const employeeName = ref(null);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);
const defaultToDate = ref(null);

const statusOptions = [
  {id: 0, name: 'Pendiente', color: 'warning', value: 0},
  {id: 1, name: 'Aprobada', color: 'success', value: 1},
  {id: 2, name: 'Rechazada', color: 'danger', value: 2},
  {id: 3, name: 'Cancelada', color: 'danger', value: 3}
]

const statusClassById = (status) => {
  if (status === 0) {
    return 'pending';
  } else if (status === 1) {
    return 'approved';
  } else if (status === 2) {
    return 'denied';
  } else if (status === 3) {
    return 'canceled';
  }
}
const statusClass = (status) => {
  if (status === 'Pendiente') {
    return 'pending';
  } else if (status === 'Aprobada') {
    return 'approved';
  } else if (status === 'Rechazada') {
    return 'denied';
  } else if (status === 'Cancelada') {
    return 'canceled';
  }
}

const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
// eslint-disable-next-line no-unused-vars
const selectedStatusFilter = ref({});
const selectedDepartmentFilter = ref({});
const departments = ref([]);
const showStatusConfirmationModal = ref(false);
const statusConfirmationMsg = ref('');
// const newStatusInfo = ref({
//   newStatus: null,
//   holidayData: null
// });

// Tabla
const holidays = ref([]);
const selectedHoliday = ref({});
const selectedIndex = ref(0);

const totalResults = ref(0);
const page = ref(1);
const itemsPerPage = ref(100);
const params = ref({});
const toDateDisabledDates = ref({
  to: null
});
const showGantt = ref(false);
const textButton = ref("Plan de producción");
const vacationPlanningData = ref({});
const totalVacations = ref(0);


const user = computed(() => {
  return store.state.user;
})


const vacationPlanning = computed(() => {
  let fromDate = selectedFromDate.value;
  let toDate = selectedToDate.value;
  let department = selectedDepartmentFilter.value;


  if (selectedFromDate.value === null && selectedToDate.value === null) {
    fromDate = moment().format('YYYY-MM-DD 00:00:00');
    toDate = moment(fromDate).add(3, 'months').format('YYYY-MM-DD 23:59:59');
  }

  if (selectedFromDate.value !== null && selectedToDate.value === null) {
    fromDate = selectedFromDate.value;
    toDate = moment(fromDate).add(3, 'months').format('YYYY-MM-DD 23:59:59');
  }

  if (selectedFromDate.value === null && selectedToDate.value !== null) {
    fromDate = moment('today').subtract(3, 'months').format('YYYY-MM-DD 00:00:00');
    toDate = selectedToDate;
  }

  if (department) {
    params.value['department'] = selectedDepartmentFilter.value.value;
  } else {
    delete params.value['department'];
  }


  return {
    'vacationPlanning': vacationPlanningData.value,
    'fromDate': fromDate,
    'toDate': toDate
  };
})

onMounted(() => {
  if (ifIsInArray(user.value.roles, 'ROLE_ADMIN')) {
    // Inicialmente buscamos las vacaciones con el estado 'Pendiente'
    selectedStatusFilter.value = statusOptions[0];
    params.value['approved'] = selectedStatusFilter.value.value;
    params.value['order[employee.name]'] = 'ASC';
    params.value['order[fromDate]'] = 'DESC';
    getHolidays();
    getVacationPlanning();
    getDepartments();
  }
})

const search = () => {
  page.value = 1;
  getHolidays();
  getVacationPlanning()
}

const disabledDate = (time) => {
  if (selectedFromDate.value) {
    defaultToDate.value = selectedFromDate.value;
    let date = new Date(selectedFromDate.value);
    return time.getTime() <= date.setDate(date.getDate() - 1);
  } else {
    return time.getTime() <= Date.now();
  }
}

/* Obtiene las vacaciones */
const getHolidays = async () => {
  delete params.value['toDate[before]'];
  delete params.value['fromDate[after]'];
  delete params.value['toDate'];
  delete params.value['fromDate'];
  // await store.dispatch('setLoading', true);
  // if (!showGantt.value) {
  if (selectedStatusFilter.value) {
    params.value['approved'] = selectedStatusFilter.value.value
  } else {
    delete params.value['approved'];
  }
  if (employeeName.value && employeeName.value.length > 0) {
    params.value.orSearch_fullName = employeeName.value
  } else {
    delete params.value.orSearch_fullName;
  }
  if (selectedDepartmentFilter.value) {
    params.value['employee.job.department.id'] = selectedDepartmentFilter.value.value;
  } else {
    delete params.value['employee.job.department.id'];
  }
  // params.value['order[fromDate]'] = 'DESC'
  params.value.page = page.value;
  params.value.itemsPerPage = itemsPerPage.value;
  if (selectedFromDate.value) {
    params.value['fromDate[after]'] = moment(selectedFromDate.value).format('YYYY-MM-DD 00:00:00')
  }
  if (selectedToDate.value) {
    params.value['toDate[before]'] = moment(selectedToDate.value).format('YYYY-MM-DD 23:59:59')
  }
  await store.dispatch('setLoading', true);
  await HolidayService.getHolidays(params.value)
      .then((res) => {
        // Inicializamos el estado de cada registro de vacaciones
        holidays.value = res.data['hydra:member'];
        holidays.value.forEach(holiday => {
          holiday.currentStatus = statusOptions[holiday.approved];

        });
        totalVacations.value = holidays.value
            .filter(holiday => holiday.approved === 1)
            .reduce((acc, holiday) => {
              acc += holiday.daysConsumed;
              return acc;
            }, 0);
        totalResults.value = res.data['hydra:totalItems'];
        store.dispatch('setLoading', false);
      }).catch(() => {
        store.dispatch('setLoading', false);
        ElNotification({
          title: 'Vacaciones',
          message: 'Error al obtener el listado de vacaciones',
          type: 'error',
          duration: 6000
        });
      });
  // }

}

const getVacationPlanning = async () => {
  await store.dispatch('setLoading', true);
  params.value.approved = 1;
  delete params.value.page;
  delete params.value.itemsPerPage;

  delete params.value['toDate[before]'];
  delete params.value['fromDate[after]'];

  delete params.value['employee.job.department.id'];

  let fromDate = selectedFromDate.value;
  let toDate = selectedToDate.value;

  if (selectedFromDate.value === null && selectedToDate.value === null) {
    fromDate = moment().format('YYYY-MM-DD 00:00:00');
    toDate = moment(fromDate).add(3, 'months').format('YYYY-MM-DD 23:59:59');
  }

  if (selectedFromDate.value !== null && selectedToDate.value === null) {
    fromDate = selectedFromDate.value;
    toDate = moment(fromDate).add(3, 'months').format('YYYY-MM-DD 23:59:59');
  }

  if (selectedFromDate.value === null && selectedToDate.value !== null) {
    fromDate = moment(toDate).subtract(3, 'months').format('YYYY-MM-DD 00:00:00');
    toDate = selectedToDate.value;
  }

  params.value.toDate = toDate;
  params.value.fromDate = fromDate;
  if (selectedDepartmentFilter.value) {
    params.value['department'] = selectedDepartmentFilter.value.value;
  } else {
    delete params.value['department'];
  }

  await HolidayService.getVacationPlanning(params.value)
      .then((res) => {
        store.dispatch('setLoading', false);
        vacationPlanningData.value = res.data['data'];
      })
      .catch(() => {
        store.dispatch('setLoading', false);
      })

}


/* Incluye un filtro según la fecha de inicio de las vacaciones */
const filterHolidaysFromDate = () => {

  if (!showGantt.value) {
    if (selectedFromDate.value != null) {
      if (selectedToDate.value == null) {
        // Caso 2)
        delete params.value['toDate[before]'];
        delete params.value['fromDate[after]'];
        params.value['fromDate[after]'] = moment(selectedFromDate).format('YYYY-MM-DD 00:00:00')

      } else {
        // caso 4)
        delete params.value['fromDate[after]'];
        delete params.value['toDate[before]'];
        params.value['fromDate[after]'] = moment(selectedToDate.value).format('YYYY-MM-DD 23:59:59')
        params.value['toDate[before]'] = moment(selectedFromDate.value).format('YYYY-MM-DD 00:00:00')
      }
    }
  } else {
    if (!selectedToDate.value) {
      // Caso 1)
      delete params.value['toDate[before]'];
      delete params.value['fromDate[after]'];
    } else {
      // Caso 3)
      delete params.value['toDate[before]'];
      delete params.value['fromDate[after]'];
      params.value['toDate[before]'] = moment(selectedToDate.value).format('YYYY-MM-DD 00:00:00')
    }

    page.value = 1;
    getHolidays();
  }

}


/* Incluye un filtro según la fecha de fin de las vacaciones */
const filterHolidaysToDate = () => {
  if (!showGantt.value) {
    if (selectedToDate.value != null) {
      if (selectedFromDate.value == null) {
        // Caso 3)
        delete params.value['fromDate[after]'];
        delete params.value['toDate[before]'];
        params.value['toDate[before]'] = moment(selectedToDate.value).format('YYYY-MM-DD 23:59:59')
      } else {
        // Caso 4)
        delete params.value['fromDate[after]'];
        params.value['fromDate[after]'] = moment(selectedToDate.value).format('YYYY-MM-DD 23:59:59')
        params.value['toDate[before]'] = moment(selectedFromDate.value).format('YYYY-MM-DD 00:00:00')
      }
    } else {
      if (selectedFromDate.value == null) {
        // Caso 1)
        delete params.value['toDate[after]'];
        delete params.value['toDate[before]'];
        delete params.value['fromDate[before]'];
        delete params.value['fromDate[after]'];
      } else {
        // Caso 2)
        delete params.value['toDate[after]'];
        delete params.value['toDate[before]'];
        delete params.value['fromDate[before]'];
        params.value['fromDate[after]'] = moment(selectedFromDate.value).format('YYYY-MM-DD 00:00:00')
      }
    }

    page.value = 1;
    getHolidays();
  } else {
    getVacationPlanning();
  }
}

/* Transita a una vista en la que se muestran las vacaciones del empleado en cuestión */
const openHolidayDetails = (holidayInfo) => {
  const EmployeeID = getIDFromUriID(holidayInfo.employee['@id']);
  router.push(`/holidays/${EmployeeID != null ? EmployeeID : 'new'}`)
}

/* Obtiene el ID de la URI pasada por párametro */
const getIDFromUriID = (uriID) => {
  if (uriID.indexOf('/') > -1) {
    let EmployeeID = uriID.split('/');
    return EmployeeID[EmployeeID.length - 1];
  }
  return null;
}


/* Evento que se ejecuta cuando cambia el selector de estado de unas vacaciones (desde la tabla) */
const specificHolidayStatusChange = (index) => {
  selectedIndex.value = index;
  selectedHoliday.value = holidays.value[index];
  const newStatus = statusOptions.find(status => status.id === selectedHoliday.value.approved);
  statusConfirmationMsg.value = 'Empleado: \t\t' + selectedHoliday.value.employee.name + ' ' + selectedHoliday.value.employee.lastName + '\n' +
      'Periodo: \t\t\t' + moment(selectedHoliday.value.fromDate).format('DD/MM/YYYY') + ' - ' + moment(selectedHoliday.value.toDate).format('DD/MM/YYYY') + '\n' +
      'Nuevo estado: \t' + newStatus.name;
  showStatusConfirmationModal.value = true;

}
const closeStatusConfirmationModal = () => {
  // Descomentar esto y hacer que funcione en Vue3
  // $refs['holiday-status-' + newStatusInfo.value.holidayData.id][0].selectItem(newStatusInfo.value.holidayData.currentStatus, false);
  holidays.value[selectedIndex.value].approved = selectedHoliday.value.currentStatus.value;
  showStatusConfirmationModal.value = false;
  selectedHoliday.value = {};
  selectedIndex.value = 0;
}
const acceptStatusConfirmationModal = async () => {
  const item = {approved: selectedHoliday.value.approved};
  await HolidayService.updateHoliday(item, selectedHoliday.value.id).then(() => {
    selectedHoliday.value.currentStatus = statusOptions.find(status => status.status === selectedHoliday.value.approved);
    ElNotification({
      title: 'Vacaciones',
      message: 'Cambio de estado de las vacaciones satisfactorio',
      type: 'success',
    });
  })
      .catch(() => {
        ElNotification({
          title: 'Vacaciones',
          message: 'Error al cambiar el estado de las vacaciones',
          type: 'error',
          duration: 6000
        }
      );
      });
  selectedHoliday.value = {};
  showStatusConfirmationModal.value = false;
}

// eslint-disable-next-line no-unused-vars
const fromDateSelected = (selectedDate) => {
  console.log("DATE", selectedDate);
  if (selectedDate != null) {
    // Deshabilitamos como fecha de fín todos los días anteriores a la fecha de 'desde'
    selectedToDate.value = null;
    toDateDisabledDates.value.to = new Date();
    toDateDisabledDates.value.to.setDate(selectedDate.getDate() - 1);
  } else {
    toDateDisabledDates.value.to = null;
  }
}

const ifIsInArray = (userRoles, role) => {
  return Common.ifIsInArray(userRoles, role);
}
const changeBetweenHolidaysOrGantt = () => {
  showGantt.value = !showGantt.value;
  showGantt.value ? textButton.value = 'Listado Vacaciones' : textButton.value = 'Plan de producción';
  search();
  // getVacationPlanning();
}

const getDepartments = (searchQuery = '') => {
  //const params = {query: {name: searchQuery}};
  const params = (searchQuery.length > 0 ? {name: searchQuery} : {});
  store.dispatch('setLoading', true);
  ResourceService.getDepartments(params).then((res) => {
    departments.value = res.data['hydra:member'];
    store.dispatch('setLoading', false);
  }).catch(() => {
    ElNotification({
      message: 'Error al obtener los departamentos',
      type: 'error',
    });
    store.dispatch('setLoading', false);
  });
}

const formatDate = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  }
  return '';
}
const formatDateTime = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm');
  }
  return '';
}

</script>

<style
    lang="scss"
    scoped>

.approved, .denied, .pending, .canceled {
  text-transform: uppercase;
}

.approved {
  color: #474CE6;
}

.denied {
  color: #F9156F;
}

.pending {
  color: #F7D20E;
}

.canceled {
  color: #FF4500;
}

.card-search {
  border-radius: 15px;
  max-height: 280px;
  background: white url("../../assets/images/camera.png") no-repeat;
  background-position-x: 100%;
  background-size: contain;
  overflow: visible !important;
}

.holidays {
  margin-bottom: 20px;
}

.pagination {
  padding: 0px 0px 20px 0px;
}

.total-vacations {
  width: 320px;
  margin-top: 15px;
  color: $font-blue-color;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.btn-new {
  margin-top: 30px;
}
</style>
