<template>
  <presence-home :email="email" />
</template>
<script>
import PresenceHome from "./PresenceHome.vue";
export default {
    components:{
        PresenceHome
    },
  data(){
    return {
        email:null,
    }
  },
  computed:{
    getUserLogged() {
      return this.$store.getters.getUserLogged;
    },
    getReportPresence(){
      return this.$store.getters['getReportPresence'];
    },
  },
  created(){
    if(this.$route.params && this.$route.params.id){
          const report = this.getReportPresence.filter(v=>v.id===this.$route.params.id)[0];
          this.email = report.email;
        
      }
    
   
  }
}
</script>