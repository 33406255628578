<template>
  <div class="row-table-image-name-link">
    <div
      v-if="employee && employee.photo && employee.photo.contentUrl && imageExists(baseUrl + employee.photo.contentUrl)"
      class="media pointer"
    >
      <img
        class="rounded-circle img-thumbnail"
        :src="baseUrl + employee.photo.contentUrl"
        :alt="'Employee image'"
        :title="'Click para ampliar'"
      >
    </div>
    <div
      v-else
      class="media"
    >
      <img
        class="rounded-circle img-thumbnail"
        src="~@/assets/images/user.png"
        :alt="'Default Image'"
      >
    </div>

    <div
      v-if="employee && employee.id && !withoutLink"
      class="link-name"
    >
      <router-link :to="{name: 'employee', params: { id: employee.id }}">
        <div v-if="isShowLastName">
          {{ employee.name }} {{ employee.lastName }}
        </div>
        <div v-if="!isShowLastName">
          {{ employee.name }}
        </div>
      </router-link>
    </div>
    <div
      v-if="employee && employee.id && withoutLink"
      class="link-name"
    >
      <div v-if="isShowLastName">
        {{ employee.name }} {{ employee.lastName }}
      </div>
      <div v-if="!isShowLastName">
        {{ employee.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
   props: {employee:Object,withoutLink:Boolean,isShowLastName:{type:Boolean,default:true}},
   data(){
    return {
        baseUrl: process.env.VUE_APP_BASE_URI,
    }
   },
   methods:{
    imageExists(image_url){
      var http = new XMLHttpRequest();
      http.open('HEAD', image_url, false);
      http.send();
      return http.status!='404';
    },
   }
}
</script>
<style lang="scss" scoped>
.link-name {
          margin: auto;
          margin-left: 10px;
        }
.img-thumbnail {
  width: 50px;
}
.row-table-image-name-link {
  display: flex;
  align-items: center;
  gap: 4px;
}

</style>