<template>
  <div class="d-flex flex-nowrap">
    <div class="d-flex flex-nowrap ">
      <div class="align-self-start">
        <svg
          id="chronometer-head"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 233.8 233.8"
        >
          <path
            d="M188.2 79.4l6.1-6.1 2.9 2.9c1.5 1.5 3.4 2.2 5.3 2.2 1.9 0 3.8-0.7 5.3-2.2 2.9-2.9 2.9-7.7 0-10.6l-16.4-16.4c-2.9-2.9-7.7-2.9-10.6 0 -2.9 2.9-2.9 7.7 0 10.6l2.9 2.9 -6.1 6.1c-10.4-8.9-22.9-15.6-36.5-19.2 4.1-5.2 6.5-11.8 6.5-18.9C147.6 13.8 133.8 0 116.9 0c-16.9 0-30.7 13.8-30.7 30.7 0 7.1 2.4 13.7 6.5 18.9 -13.6 3.6-26 10.3-36.5 19.2l-6.1-6.1 2.9-2.9c2.9-2.9 2.9-7.7 0-10.6 -2.9-2.9-7.7-2.9-10.6 0L26 65.7c-2.9 2.9-2.9 7.7 0 10.6 1.5 1.5 3.4 2.2 5.3 2.2s3.8-0.7 5.3-2.2l2.9-2.9 6.1 6.1c-13.9 16.4-22.4 37.6-22.4 60.7 0 51.6 42 93.7 93.7 93.7s93.7-42 93.7-93.7C210.5 117 202.1 95.8 188.2 79.4zM101.2 30.7c0-8.7 7.1-15.7 15.7-15.7s15.7 7.1 15.7 15.7 -7.1 15.7-15.7 15.7S101.2 39.4 101.2 30.7zM116.9 218.8c-43.4 0-78.7-35.3-78.7-78.7 0-43.4 35.3-78.7 78.7-78.7 43.4 0 78.7 35.3 78.7 78.7C195.5 183.5 160.3 218.8 116.9 218.8z"
          />
          <path
            d="M116.9 77c-4.1 0-7.5 3.4-7.5 7.5v55.6c0 4.1 3.4 7.5 7.5 7.5 4.1 0 7.5-3.4 7.5-7.5V84.5C124.4 80.3 121 77 116.9 77z"
          />
        </svg>
      </div>
      <div class="d-flex flex-column align-self-start">
        <span class="text-presence">Presencia</span>
        <h2>{{ secondsToTime(store.state.hoursWorked) }}</h2>
      </div>
    </div>
    <div class="d-flex align-items-center" style="margin-left: 15px;">
      <button
          style="max-width: 150px"
        :class="store.state.sign_button ? 'btn_yellow':'btn_red'"
        class="btn btn-block btn-action ml-3"
        :disabled="waitingSignUp"
        @click="addSignUp()"
      >
        {{
          store.state.sign_button ? 'EMPEZAR' :
          'TERMINAR'
        }}
      </button>
    </div>
    <!-- <shared-modal
      v-if="showmodal"
      :title="'Fichaje desde Presencia'"
      :theme="'secondary'"
      :size="'small'"
      @close="closeSignModal()"
    >
      <template v-slot:content>
        <br />
        <div>
          <p>
            <font-awesome-icon icon="exclamation-triangle" />
            Confirma que estas fuera de la oficina
          </p>
          <div class="row item-modal">
            <div class="col-sm-6">
              <button
                class="btn btn-block btn_red"
                type="button"
                @click="closeSignModal()"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-6">
              <button class="btn btn_blue" type="button" @click="addSignUp()">
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </template>
    </shared-modal> -->
    <PopupModal
      :is-show="showmodal"
      @accept="addSignUp()"
    />
  </div>
</template>
<script setup>

import PopupModal from "@/components/shared/PopupSing.vue";
import PresenceService from "@/api/services/presencia.service";
import CommonService from "@/api/services/common.service";
import moment from "moment";
import Notification from '@/utils/plugins/NotificationService'
import {useStore} from "vuex";
import {computed, onMounted, onUnmounted, ref, defineProps} from "vue";
import { ElNotification } from "element-plus";


const store = useStore();
defineProps({
  title: {
    type: String,
    default: ""
  },
  showCard: {
    type: Boolean,
    default: false
  }
})

onMounted(()=> {
  getServerTime();

})
onUnmounted(()=>{
  clearInterval(interval.value);
})
const interval = ref('');
const now = ref('');
const showmodal = ref(false);
const waitingSignUp = ref(false);
const signings = ref([]);
const currentSign = ref([]);
// eslint-disable-next-line no-unused-vars
const user = computed(()=>{
  return store.state.user;
})


const getServerTime = async() => {
  CommonService.getServerTimestamp().then((response) => {
    now.value = moment(response.data.serverTimestamp, "YYYY-MM-DDTHH:mm:ss");
    getPresenceData();
    store.dispatch('calculateWorkedTime', now.value);
    interval.value = setInterval(async () => {
      now.value.add(60, 'seconds');
      await store.dispatch('calculateWorkedTime', now.value);
      // console.log('add e minute',now);
    }, 60000);


  });
}
const getPresenceData = () => {
  const user = store.state.user;
  let from_date = "";
  let to_date = "";

  from_date = now.value.format("YYYY-MM-DD");
  to_date = now.value.format("YYYY-MM-DD");

  signings.value = [];
  currentSign.value = [];

  Notification.addNotification('Funciona', 'success', 6000);

  PresenceService.getSignings({
    email: user.email,
    from: from_date,
    to: to_date,
  }).then((response) => {
    waitingSignUp.value = false;
    
    if (response.data && response.data.days) {
      const todaySigns = response.data.days.find(day => day.date === now.value.format("YYYYMMDD"));
      if (todaySigns !== undefined && todaySigns.signings.length % 2) {
        store.commit('setSignButton', false);
        // console.log("Terminar" , false);
        response.data.days.forEach((element) => {
          //signings = response.data.days;
          //console.log("day",response.data);
          // console.log("Element", element);
          // console.log("ElementSignings", element.signings);
          store.dispatch('currentPresence', element.signings);
        });
      } else {
        store.commit('setSignButton', true);
        // console.log("Empezar" , true);
        response.data.days.forEach((element) => {
          //signings = response.data.days;
          //console.log("day",response.data);
          // console.log("Element", element);
          // console.log("ElementSignings", element.signings);
          store.dispatch('currentPresence', element.signings);
        });
      }
    }

    // if (response.data && (response.data.length === 0 || response.data.days.length === 0)) {
    //     console.log("data", response.data.length);
    //     console.log("days", response.data.days.length);
    //
    //   store.commit('setSignButton', true);
    //   clearInterval(this.interval);
    // } else {
    //   response.data.days.forEach((element) => {
    //     //signings = response.data.days;
    //     //console.log("day",response.data);
    //     console.log("Element", element);
    //     console.log("ElementSignings", element.signings);
    //     store.dispatch('currentPresence', element.signings);
    //
    //
    //   });
    // }

  }).catch(() => {
      Notification.addNotification('Error al fichar', 'error', 6000)    
  });
}
const addSignUp = () => {
  //this.closeSignModal();
  waitingSignUp.value = true;
  let mssg;

  if (store.state.sign_button) {
    clearInterval(interval.value);
    mssg = 'Presencia registrada con éxito';  
  }else{
    mssg = 'Presencia finalizada con éxito';  
  }
  ElNotification({
          type: 'success',
          message: mssg,
          duration: 6000
      })  
  store.commit('setStatusSignButton');
  store.dispatch('addSigning').then(() => getPresenceData());
}

// eslint-disable-next-line no-unused-vars
const showSignModal = () => {
  showmodal.value = !showmodal.value;
  getServerTime();
}

// eslint-disable-next-line no-unused-vars
const closeSignModal = () =>{
  showmodal.value = false
}

const secondsToTime =  (s) => {
  if (!s) return '';

  function addZ(n) {
    return (n < 10 ? '0' : '') + n;
  }

  let ms = Number(s) % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;

  return addZ(hrs) + 'h:' + addZ(mins) + 'm';
}
// eslint-disable-next-line no-unused-vars
const formatTimeStyle = (value) => {
  // console.log(value);
  if (value) {
    let hours = value.hours().toString().length === 1 ? '0' + value.hours().toString() : value.hours();
    let minutes = value.minutes().toString().length === 1 ? '0' + value.minutes().toString() : value.minutes();

    return hours + 'h:' + minutes + 'm';

  }
  return '';
}


</script>
<style lang="scss" scoped>
@import "../../assets/sass/styles.scss";

#chronometer-head {
  fill: $yellow-color;
  width: 50px;
  margin-right: 5px;
  margin-top: 15px;
}

.text-presence {
  font-size: 4em;
  font-weight: 900;
  letter-spacing: -0.05em;
}

.btn-action {
  width: 20em;
  height: 3em;
  font-weight: bold;
}


h1 {
  text-align: left;
  font-size: 4em;
  font-weight: 900;
  letter-spacing: -0.05em;
}


.btn-on {
  background-color: red;
}

.btn-off {
  background-color: green;
}


</style>
